import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import AkHomeFilledIcon from '@atlaskit/icon/core/home';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HomepageContainer = styled.div({
	display: 'inline-block',
	verticalAlign: 'middle',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginRight: '5px',
});

export const HomepageIcon: FC = () => (
	<HomepageContainer data-testid="space-homepage">
		<AkHomeFilledIcon label="" />
	</HomepageContainer>
);
